import React from "react";

// others

import { Col, Row, Spin } from "antd";
import { apiDateFormat } from "../../BookingForm/components/utils";
import { getKycLabel } from "../../BookingForm/components/utils";
import { Item } from "../../BookingForm/components/view";

interface IProps {
    booking: any;
    kycDetails?: Array<object>;
}

const TerminationDetail = (props: IProps) => {
    // variables
    const format = "DD-MMM-YYYY";
    const isBooking = props?.booking && Object.keys(props.booking).length > 0;

    const valueStyle = { textTransform: "capitalize" };

    const usagesType = props?.booking?.propertyDetails?.usagesType;

    return (
        <Col span={24} className="view-booking">
            {!isBooking && (
                <Row gutter={24} className="loading">
                    <Spin size="large" />
                </Row>
            )}

            {isBooking && (
                <>
                    <Row gutter={24} className="bd-item">
                        <Item
                            label="Move-out Type"
                            value={'Early'}
                        />
                        <Item
                            label="Move-out Date"
                            value={'12-12-2026'}
                        />
                        <Item
                            label="Move-out Inspection"
                            value={'12-12-2026'}
                        />
                        <Item
                            label="Lease Term"
                            value={`365`}
                        />

                        <Item
                            label="Days Occupied"
                            value={`400`}
                        />

                        <Item                      
                             label="Rent Per Day"
                            value={`AED 40`}
                        />

                        <Item
                            label="Security Deposit"
                            value={`AED 400`}
                        />
                        <Item
                            label="Rent Received Until "
                            value={`5-5-2024`}
                        />
                        <Item
                            label="Actual Rent Due"
                            value={`AED 16540.5`}
                        />
                        <Item
                            label="Maintenance Fee "
                            value={`AED 50`}
                        />
                        <Item
                            label="Refurbishment Fee"
                            value={`AED 20`}
                        />

                        <Item
                            label="Other / Admin Fee"
                            value={`AED 5`}
                        />

                        <Item
                             
                            label="Overstay"
                            value={`40 Days`}
                        />

                        <Item
                            label="Termination Charge"
                            value={`AED 25`}
                        />

                        <Item
                            label="Deduction / Waiver"
                            value={`AED 0`}
                        />

                        <Item
                            label="Settlement Amount"
                            value={`AED 5000`}
                        />

                       

                    </Row>



                    {/* {props?.kycDetails?.length > 0 &&
            Object.keys(props.booking?.kycDetails || {})?.length > 0 && (
              <Row gutter={24} className="bd-item">
                {props?.kycDetails?.length > 0 &&
                  Object.keys(props.booking?.kycDetails)?.map((key) => {
                    if (props?.booking?.kycDetails?.[key])
                      return (
                        <Item
                          key={key}
                          label={getKycLabel(key, props?.kycDetails)}
                          value={key === 'dateofjoining' ?  apiDateFormat(props?.booking?.kycDetails?.[key],format):props?.booking?.kycDetails?.[key]}
                        />
                      );
                  })}
              </Row>
            )} */}
                </>
            )}
        </Col>
    );
};

export default TerminationDetail;
