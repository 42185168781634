import * as action_types from "./constants";

const initialState = {
  bookingList: [],
  bookingPendingRequests: { data: [], totalCount: 0 },
  leasePendingRequests: { data: [], totalCount: 0 },
  planPendingRequests: { data: [], totalCount: 0 },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_BOOKINGFORMS:
      return { ...state, bookingList: data };
    case action_types.GET_BOOKING_REQUESTS:
       
   
        data?.data?.length > 0 && data?.data?.forEach((booking)=>{
          booking['approvalType'] = data?.approvalType
        
        
       })
      
      //  console.log(data?.data,'  data?.data')
      if(data?.data?.length > 0){ 
        data?.data?.length > 0 && data?.data?.forEach((booking)=>{
          booking['approvalType'] = data?.approvalType
       })
        return { ...state, bookingPendingRequests: data };
      }
       
  
    case action_types.GET_LEASE_REQUESTS:
      data?.data?.length > 0 && data?.data?.forEach((booking)=>{
        booking['approvalType'] = data?.approvalType
     })
      return { ...state, leasePendingRequests: data };
    case action_types.GET_PLAN_REQUESTS:
      data?.data?.length > 0 && data?.data?.forEach((booking)=>{
        booking['approvalType'] = data?.approvalType
     })
      return { ...state, planPendingRequests: data };
    default:
      return state;
  }
};

function forEach(arg0: (booking: any) => void) {
  throw new Error("Function not implemented.");
}
