import React, { useState, useEffect, useRef } from "react";

// others
import { Loading } from "./index";
import Button from "../../../../molecules/Button";
import { useForm, useWatch } from "react-hook-form";
import { Calendar } from "../../../../../assets/svg";
import { Col, Row, Form, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  updateBooking,
  getTenantDetailByEmail,
  getLeaseTerms,
  getBookingDetail,
} from "../../ducks/actions";
import {
  DateField,
  InputField,
  ReactSelectField,
  RangePickerField,
  InputCheckbox,
} from "../../../../atoms/FormElement";
import {
  dateFormat,
  lease_nature,
  apiDateFormat,
  validateEmail,
  filterPayload,
  datePlaceholder,
  terms_conditions,
  disableMoveInDate,
  dateBookingFormat,
  filterDropdownValue,
  rentFreePeriodValues,
  disableRentPeriodDates,
  rangePickerInitialValue,
  disableLeasePeriodDates,
  dateBookingDDMMYYFormat,
  disableMoveInBookingDate,
  disableFitoutPeriodDates,
  disableBookingPeriodDates,
  disableLeasePeriodEditDates,
  datePlaceholderddmmyyFormat,
  apiDateTimeFormat,
} from "../utils";

interface IProps {
  renewal?: boolean;
  parentState: (obj: object) => void;
}

let initialState = {
  visible: false,
  authorizedSignatory: "",
};

var _ = require("lodash");
import dayjs from "dayjs";

const index = (props: IProps) => {
  // hooks initialization
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();
  const [termsandConditions, setTermsandConditions] = useState([]);
  const [termsandConditionsId, setTermsandConditionsId] = useState({});

  const leaseEndDate = useRef(null);
  

  // redux states
  const { bookingForm, landlordUsers, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const email = useWatch({ control, name: "email" });
  const isBooking = Object.keys(bookingDetails).length > 0;

  const usagesType = bookingDetails?.propertyDetails?.usagesType;

  // states
  const [{ visible, authorizedSignatory }, setState] = useState(initialState);
  const [disableTermDates, setDisableTermDates] = useState(null);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(
          getLeaseTerms(
            bookingDetails.propertyId,
            bookingDetails?.propertyDetails?.usagesType
          )
        );
        const term = response.map((term) => ({
          value: term._id,
          label: term.templateName,
        }));
        setTermsandConditions(term);
        setTermsandConditionsId({
          value: bookingDetails.termsConditionId,
          label: term.find((t) => t.value === bookingDetails.termsConditionId)
            ?.label,
        });
      } catch (e) { }
    })();
  }, [bookingDetails, bookingForm]);

  useEffect(() => {
    if (validateEmail(email) && !bookingForm?.email)
      dispatch(getTenantDetailByEmail(email));
  }, [email]);

  const onSubmit = async (data: any, type) => {
    clearErrors();
    updateState({ visible: true });
    let dataCopy = { ...(data || getValues()) };
    if (dataCopy.numberOfOcupants && !/^\d+$/.test(dataCopy.numberOfOcupants)) {
      setError("numberOfOcupants", {
        type: "manual",
        message: "Please enter valid number of ocupants",
      });
      return;
    }
    updateState({ visible: true });

    const commercialFields =
      usagesType == "Commercial" && dataCopy?.fitOutPeriod
        ? {
          fitOutEndDate: apiDateFormat(dataCopy?.fitOutPeriod[1]),
          fitOutStartDate: apiDateFormat(dataCopy?.fitOutPeriod[0]),
        }
        : {}; //This code added for diff payload: comm, resi

    const residentialFields =
      usagesType != "Commercial"
        ? { numberOfOcupants: dataCopy?.numberOfOcupants }
        : {};

    const rentFreePeriod = parseInt(dataCopy?.rentFreePeriod?.value)

    let body = {
      leaseManager: dataCopy.leaseManager.value,
      ...residentialFields,
      externalAgent: dataCopy.externalAgent,
      termsConditionId: dataCopy?.termsConditionId?.value,
      leaseTerms: {
        leaseType: "new",
        leaseNature: dataCopy.leaseNature.value,
        moveInDate: apiDateFormat(dataCopy.moveInDate),
        leaseEndDate: apiDateFormat(
          dataCopy.leaseTermPeriod && dataCopy.leaseTermPeriod[1]
        ),
        leaseStartDate: apiDateFormat(
          dataCopy.leaseTermPeriod && dataCopy.leaseTermPeriod[0]
        ),
        rentFreeEndDate: rentFreePeriod > 0 ? apiDateFormat(dataCopy.leaseTermPeriod && dataCopy.leaseTermPeriod[1]) : '',
        rentFreeStartDate: rentFreePeriod > 0 ? leaseEndDate.current ? apiDateFormat(dayjs(leaseEndDate.current).add(1, 'day')) : apiDateFormat(bookingForm?.leaseTerms?.rentFreeStartDate) : '',
        ...commercialFields,
      },
      bookingTerms: {
        ...bookingForm?.bookingTerms,
        bookingEndDate: apiDateTimeFormat(
          dataCopy.bookingPeriod && dataCopy.bookingPeriod[1]
        ),
        bookingStartDate: apiDateTimeFormat(
          dataCopy.bookingPeriod && dataCopy.bookingPeriod[0]
        ),
      },
    };

    //FOR TASK 8442
    if (body?.leaseTerms?.moveInDate)
      body.leaseTerms['moveInDateConfirm'] = dataCopy.moveInDateConfirm ? 'yes' : 'no';

    try {
      const response = await dispatch(
        updateBooking({ leaseId, body: filterPayload(body) })
      );

      setTimeout(() => {
        dispatch(getBookingDetail(leaseId));
      }, 400);

      message.success(response.data.message);
      updateState({ visible: false });
      props.parentState({
        modalPayload: { iKey: "", title: "", extraText: "", open: false },
      });
    } catch (error) {
      updateState({ visible: false });
      props.parentState({ visible: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  };

  const setLeaseEndDateFun = (e) => {
    // console.log(e);
    leaseEndDate.current = e;
  }

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <Loading visible={visible} />

      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <ReactSelectField
              control={control}
              isRequired={true}
              setValue={setValue}
              label="Lease Executive"
              fieldname="leaseManager"
              selectOption={landlordUsers}
              iProps={{ placeholder: "Select" }}
              rules={{ required: "Please select one" }}
              validate={errors.leaseManager && "error"}
              validMessage={errors.leaseManager && errors.leaseManager.message}
              valueGot={filterDropdownValue(
                bookingForm?.leaseManager?.userId || bookingForm?.leaseManager,
                landlordUsers
              )}
            />
          </Col>

          <Col span={8}>
            <InputField
              control={control}
              setValue={setValue}
              label="External Agent"
              fieldname="externalAgent"
              valueGot={bookingDetails?.externalAgent || "empty"}
              validate={errors.externalAgent && "error"}
              validMessage={
                errors.externalAgent && errors.externalAgent.message
              }
            />
          </Col>

          <Col span={8}>
            <ReactSelectField
              isRequired={true}
              control={control}
              setValue={setValue}
              label="Lease Nature"
              fieldname="leaseNature"
              selectOption={lease_nature}
              iProps={{ placeholder: "Select" }}
              validate={errors.leaseNature && "error"}
              rules={{ required: "Please select lease nature" }}
              validMessage={errors.leaseNature && errors.leaseNature.message}
              valueGot={filterDropdownValue(
                bookingForm?.leaseTerms?.leaseNature,
                lease_nature
              )}
            />
          </Col>

          {/* next row */}
          {usagesType != "Commercial" && (
            <Col span={8}>
              <InputField
                initValue={1}
                control={control}
                isRequired={true}
                setValue={setValue}
                label="No. of Occupants"
                fieldname="numberOfOcupants"
                valueGot={bookingForm?.numberOfOcupants}
                validate={errors.numberOfOcupants && "error"}
                rules={{ required: "Please enter occupants" }}
                validMessage={
                  errors.numberOfOcupants && errors.numberOfOcupants.message
                }
              />
            </Col>
          )}

          <Col span={8}>
            <ReactSelectField
              control={control}
              setValue={setValue}
              label="Terms & Conditions"
              selectOption={termsandConditions}
              fieldname="termsConditionId"
              iProps={{
                placeholder: "Select",
                addonAfter: <Calendar />,
                suffixIcon: <Calendar />,
              }}
              validate={errors.termsAndConditions && "error"}
              validMessage={
                errors.termsAndConditions && errors.termsAndConditions.message
              }
              valueGot={termsandConditionsId}
            />
          </Col>

          {!props.renewal && (
            <Col span={8}>
              <RangePickerField
                isRequired={true}
                control={control}
                setValue={setValue}
                label="Booking Period"
                fieldname="bookingPeriod"
                validate={errors.dateRange && "error"}
                initValue={rangePickerInitialValue(
                  bookingDetails,
                  "booking_period"
                )}
                onDateChange={(date) => {
                  setValue("bookingPeriod", [null, null]);
                  setValue("bookingPeriod", [
                    date[0],
                    dayjs(date[0]).add(14, "days"),
                  ]);
                  if (
                    date &&
                    date[0] &&
                    usagesType.toLowerCase() !== "commercial"
                  ) {
                    setValue("leaseTermPeriod", [
                      dayjs(date[0]), //.add(14, "days") removed as per feedback vish
                      dayjs(date[0]).add(1, "year").subtract(1, "day"),  //.add(14, "days") removed as per feedback vish
                    ]);
                    setValue("moveInDate", dayjs(date[0]));  //.add(14, "days") removed as per feedback vish
                  }
                }}
                iProps={{
                  suffixIcon: <Calendar />,
                  format: datePlaceholderddmmyyFormat,
                  placeholder: ["Start Date", "End Date"],
                  disabledDate: (date: any) =>
                    disableBookingPeriodDates(date, getValues("bookingPeriod")),
                  // onCalendarChange: (date: any) => {
                  //   if (date?.length > 1) {
                  //     const startDate = dayjs(date[1]);
                  //     const endDate = dayjs(date[1]).add(1, 'year')
                  //     const rentStartDate = dayjs(endDate).add(1, 'day')
                  //     const rentEndDate = dayjs(rentStartDate).add(3, 'months')
                  //     if(startDate) {
                  //       setDisableTermDates([startDate, endDate])
                  //       setValue("leaseTermPeriod", [startDate, endDate]);
                  //       setValue("rentFreePeriod", [rentStartDate, rentEndDate]);
                  //     }
                  //   }
                  // },
                }}
                validMessage={
                  errors.bookingPeriod && errors.bookingPeriod.message
                }
              />
            </Col>
          )}

          {usagesType == "Commercial" && !props.renewal && (
            <Col span={8}>
              <RangePickerField
                control={control}
                setValue={setValue}
                label="Fit-Out Period"
                fieldname="fitOutPeriod"
                validate={errors.fitOutPeriod && "error"}
                onDateChange={(date) => {
                  if (date && date[1]) {
                    setValue("leaseTermPeriod", [
                      dayjs(date[1]).add(1, "day"),
                      dayjs(date[1]).add(1, "year"),
                    ]);
                    setValue("moveInDate", dayjs(date[1]).add(1, "day"));
                  }
                  setValue("rentFreePeriod", null);
                }}
                initValue={rangePickerInitialValue(
                  bookingDetails,
                  "fitout_period"
                )}
                iProps={{
                  suffixIcon: <Calendar />,
                  format: datePlaceholderddmmyyFormat,
                  placeholder: ["Start Date", "End Date"],
                  disabledDate: (date: any) =>
                    disableFitoutPeriodDates(date, getValues("bookingPeriod")),
                }}
              />
            </Col>
          )}

          <Col span={8}>
            <RangePickerField
              isRequired={true}
              control={control}
              label="Lease Term"
              setValue={setValue}
              fieldname="leaseTermPeriod"
              validate={errors.leaseTermPeriod && "error"}
              initValue={rangePickerInitialValue(
                bookingDetails,
                "lease_period"
              )}
              onDateChange={(date) => {


                // setValue("leaseTermPeriod", [null, null]);
                // if (date[0]){
                let endDate = dayjs(date[0]).add(1, "year").subtract(1, "day");
                console.log('hit..1', apiDateFormat(date[0]), apiDateFormat(date[1]), endDate);
                setTimeout(() => {
                  setValue("leaseTermPeriod", [
                    date[0],
                    endDate
                  ]);
                }, 500);
                setLeaseEndDateFun(endDate);
                // }
                // else
                //   setLeaseEndDateFun(date[1])
                setValue("moveInDate", date[0]);
                setValue("rentFreePeriod", null);
              }}
              // onChange={(date) => {
              //   console.log('hit..2', apiDateFormat(date[0]), apiDateFormat(date[1]));
              //   let endDate = dayjs(date[0]).add(1, "year").subtract(1, "day");
              //   setValue("leaseTermPeriod", [
              //     date[0],
              //     endDate
              //   ]);
              //   // if(date && date[1]) {
              //   //   setLeaseEndDateFun(date[1])
              //   //   initialLeaseEndDate.current = date[1]
              //   // }
              //   setValue("moveInDate", date[0]);
              //   setValue("rentFreePeriod", null);
              // }}
              iProps={{
                suffixIcon: <Calendar />,
                format: datePlaceholderddmmyyFormat,
                placeholder: ["Start Date", "End Date"],
                //   disabledDate: (date: any) => disableDate(date),
                // onCalendarChange: (date: any) => {
                //   console.log("date------>",date[0],"date2------->",date[1]);
                //   const startDate = dayjs(date[0]).isValid() ? dayjs(date[0]) : "";
                //   const endDate = dayjs(date[1]).isValid() ? dayjs(date[1]) : "";
                //   // const rentStartDate = dayjs(endDate).add(1, 'day')
                //   // const rentEndDate = dayjs(rentStartDate).add(3, 'months')
                //   setValue("leaseTermPeriod", [startDate, endDate]);
                //   // setValue("rentFreePeriod", [rentStartDate, rentEndDate]);
                // },
                // onChange: (date: any) => {},
                disabledDate: (date: any) =>
                  disableLeasePeriodDates(date, getValues("leaseTermPeriod"), getValues("bookingPeriod"))
              }}
              validMessage={
                errors.leaseTermPeriod && errors.leaseTermPeriod.message
              }
            />
          </Col>

          {/* next row */}
          {!props.renewal && (
            <Col span={8}>
              <ReactSelectField
                control={control}
                setValue={setValue}
                label="Rent Free Period"
                fieldname="rentFreePeriod"
                selectOption={rentFreePeriodValues()}
                onChange={(e) => {
                  const currStart = getValues("leaseTermPeriod")?.[0];
                  if (currStart) {
                    setValue("leaseTermPeriod", [
                      currStart,
                      dayjs(currStart).add(1, 'year').subtract(1, 'day').add(parseInt(e.value), "days"),
                    ])
                  }
                  // else setValue("leaseTermPeriod", [
                  //   getValues("leaseTermPeriod")?.[0],
                  //   dayjs(bookingForm?.leaseTerms?.rentFreeStartDate).subtract(1, 'day').add(parseInt(e.value), "days"),
                  // ])
                }}
                iProps={{ placeholder: "Select" }}
                valueGot={bookingForm?.leaseTerms?.rentFreeStartDate && bookingForm?.leaseTerms?.rentFreeEndDate && filterDropdownValue(
                  String(dayjs(bookingForm?.leaseTerms?.rentFreeEndDate).diff(dayjs(bookingForm?.leaseTerms?.rentFreeStartDate), 'days') + 1),
                  rentFreePeriodValues()
                )}
              />
            </Col>
            // <Col span={8}>
            //   <RangePickerField
            //     control={control}
            //     setValue={setValue}
            //     label="Rent Free Period"
            //     fieldname="rentFreePeriod"
            //     validate={errors.rentFreePeriod && "error"}
            //     initValue={rangePickerInitialValue(
            //       bookingDetails,
            //       "rent_period"
            //     )}
            //     onChange={(date) => {
            //       if (date)
            //         setValue("leaseTermPeriod", [
            //           getValues("leaseTermPeriod")[0],
            //           date[1],
            //         ]);
            //     }}
            //     iProps={{
            //       suffixIcon: <Calendar />,
            //       format: datePlaceholderddmmyyFormat,
            //       placeholder: ["Start Date", "End Date"],
            //       disabledDate: (date: any) =>
            //         disableRentPeriodDates(date, getValues("leaseTermPeriod")),
            //     }}
            //     validMessage={
            //       errors.rentFreePeriod && errors.rentFreePeriod.message
            //     }
            //   />
            // </Col>
          )}

          {/* Need confirmation from usama balu for this field: removed for now */}
          {/* {!props.renewal && (
            <Col span={8}>
              <RangePickerField
                isRequired={true}
                control={control}
                setValue={setValue}
                label="Rent Free Period"
                fieldname="rentFreePeriod"
                validate={errors.rentFreePeriod && "error"}
                initValue={rangePickerInitialValue(bookingDetails, "rent_period")}
                iProps={{
                  suffixIcon: <Calendar />,
                  placeholder: ["Start Date", "End Date"],
                  onCalendarChange: (date: any) => {
                    const startDate = dayjs(date[0]);
                    const endDate = dayjs(date[1])
                    setValue("rentFreePeriod", [startDate, endDate]);
                  },
                  disabledDate: (date: any) =>
                  disableRentPeriodDates(date, getValues("leaseTermPeriod")),
                }}
                validMessage={
                  errors.rentFreePeriod && errors.rentFreePeriod.message
                }
              />
            </Col>
          )} */}
          <Col span={8} style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: '14px', top: '-1px' }}>
              <InputCheckbox
                fieldname="moveInDateConfirm"
                label="Confirmed"
                control={control}
                classes={`prm-checkbox`}
                iProps={{
                  className: `prm-checkbox`,
                }}
                valueGot={bookingForm?.leaseTerms?.moveInDateConfirm == 'yes' ? true : ''}
                setValue={setValue}
                // validate={errors.confirmed && "error"}
                // validMessage={errors.confirmed && errors.confirmed.message}
                initValue=""
              // rules={{ required: "Should accept agreement" }}
              />
            </div>
            <DateField
              control={control}
              isRequired={true}
              setValue={setValue}
              label="Move-In Date"
              fieldname="moveInDate"
              rules={{ required: "Enter Date" }}
              validate={errors?.moveInDate && "error"}
              valueGot={dateBookingDDMMYYFormat(
                bookingForm?.leaseTerms?.moveInDate
              )}
              validMessage={errors?.moveInDate && errors?.moveInDate.message}
              iProps={{
                format: datePlaceholderddmmyyFormat,
                placeholder: datePlaceholderddmmyyFormat,
                disabledDate: (date) =>
                  disableMoveInDate(
                    date,
                    getValues("leaseTermPeriod")[0],
                    getValues("bookingPeriod")[0]
                  ),
              }}
            />
          </Col>
        </Row>

        <Row gutter={24} className="footer">
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            />

            <Button
              type="submit"
              label="Update"
              className="cc-fotter_next-button"
            />
          </Space>
        </Row>
      </Col>
    </Form>
  );
};

export default index;