import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Spin,
  Tag,
} from "antd";

import Grid from "../../../atoms/Grid/index";


import { useNavigate } from "react-router-dom";

import { dateByformat } from "../../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../../utils/media";



const { Content } = Layout;


const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};



export default ({

  getAllBookRequests,
  getAllLeaseRequests,
  getAllPlanRequests,
}) => {


  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
 
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (
      getAllBookRequests?.data ||
      getAllLeaseRequests?.data ||
      getAllPlanRequests?.data
    )
      setDataList([
        ...getAllBookRequests?.data,
        ...getAllLeaseRequests?.data,
        ...getAllPlanRequests?.data,
      ]);
      
  }, [getAllBookRequests, getAllLeaseRequests, getAllPlanRequests]);


  const dataCol = [
    {
      headerName: "Approval Type",
      field: "approvalType",
      cellRendererFramework: ({data} ) => {

        console.log(data?.approvalType,'approvalType')
        return(
         
          <Tag className="tag-sm" color="success">
           {data?.approvalType}
          </Tag>
        )
      }
    },
    {
      headerName: "Status",
      field: "leaseStatus",
      minWidth: 220,
      cellRendererFramework: ({ data }) => {
    
         return(
          <Tag className="tag-sm" color="warning">
            {statusLabel(data?.leaseStatus)}
          </Tag>
        )
      }
    },
    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
      // minWidth: 320,
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
    },
    {
      headerName: "Annual Rent",
      field: "bookingTerms.annualBaseRent",
      cellRendererFramework: ({ data }) => {
        return (
          <div className="annualRent">
            {currencyFormat(
              data?.bookingTerms?.renewalAmount ||
                data?.bookingTerms?.annualBaseRent
            )}
          </div>
        );
      },
    },
    {
      headerName: "Payments",
      field: "bookingTerms.numberOfInstallments",
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",

      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
    
      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "Contract Type",
      field: "leaseType",
      cellRendererFramework: ({ data }) => {
        return statusLabel(data?.leaseType);
      },
    },
    {
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    
    {
      headerName: "Action",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "center" },
      maxWidth: 240,
      filter: false,
    },
  ];

  function viewRender({ data }) {
    return (
      <>
        
        <Button
          type="primary"
          onClick={() => data?.leaseStatus === "draft" ? navigate(`/newbookingform/${data?.leaseId}`) : navigate(`/viewbookingform/${data?.leaseId}`)} 
        >
          View
        </Button>
       
      </>
    );
  }



  return (
    <Layout className="layout-space prop_popup">
      <Content className="main-content">
        {!loading && (
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="end">
                    <Col span={24}>
                      <Grid
                        // ref={gridRef}
                        data={dataList}
                        columns={dataCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Payments"
                        fullPage={true}
                        overlayNoRowsTemplate={"Payments data cannot be found."}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {loading && (
          <div
            className="justify-center"
            style={{ textAlign: "center", minHeight: "255px" }}
          >
            <Spin />
          </div>
        )}
      </Content>
    </Layout>
  );
};
