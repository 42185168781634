import * as action_types from "./constants";

const initialState = {
  contractList: [],
  contractData: {},
  renewalFilters: {},
  moveoutFilters: {},
  pdcList: [],
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_CONTRACTS:
      return { ...state, contractList: data };
    case action_types.GET_CONTRACT:
      return { ...state, contractData: data };
    case action_types.GET_PDC:
      return { ...state, pdcList: data };
    case action_types.SET_RENEWAL_FILTERS:
      return { ...state, renewalFilters: data };
    case action_types.SET_MOVEOUT_FILTERS:
      return { ...state, moveoutFilters: data };
    default:
      return state;
  }
};
