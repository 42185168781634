import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  Checkbox,
  TimePicker,
  Upload,
  Radio,
  InputNumber,
  Switch,
  AutoComplete
} from "antd";
import CreatableSelect from "react-select/creatable";
import { Controller } from "react-hook-form";
import { default as RSelect } from "react-select";
import { MaskedInput } from "antd-mask-input";
import { translateService } from "../../modules/BookingForm/ducks/services";
const { RangePicker } = DatePicker;

export const InputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    tooltip,
    disabled,
    isPatteren
  } = props;


  const [fieldRules, setFieldRules] = useState({});
  const [valueGot, setValueGot] = useState(null);

  useEffect(() => {
    setValueGot(props?.valueGot);
  }, [props?.valueGot]);

  useEffect(() => {
    valueGot != null &&
      valueGot != undefined &&
      valueGot != false &&
      props.setValue(fieldname, valueGot);
    if (valueGot == "empty") {
      props.setValue(fieldname, "");
    } else if (valueGot == 0) {
      props.setValue(fieldname, 0);
    }
  }, [valueGot]);

  useEffect(() => {
    if (props.disabled == true) {
      setFieldRules({});
      if (props?.register) props?.register(fieldname, { required: false });
      if (props?.resetField && !valueGot) props?.resetField(fieldname);
    } else {
      setFieldRules(rules);
    }
  }, [props.disabled,isRequired]);

  const extendedFieldRules = {
    ...fieldRules,
    pattern: {
      value: /^\S.*$/,
      message: 'Spaces are not allowed',
    },
  };
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
        tooltip={tooltip}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={isRequired ? extendedFieldRules : fieldRules}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              value={value ?? ""}
              ref={ref}
              onChange={(e) => {
                onChange(e);  // send value to hook form
                props.onChange && props.onChange(e,null);
              }}
              
              onBlur={props.onBlur}
              {...iProps}
              disabled={props.disabled}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputAutocompleteField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    tooltip,
    options,
    style,
    filterOption
  } = props;

  const [fieldRules, setFieldRules] = useState({});
  const [valueGot, setValueGot] = useState(null);

  useEffect(() => {
    setValueGot(props?.valueGot);
  }, [props?.valueGot]);

  useEffect(() => {
    valueGot != null &&
      valueGot != undefined &&
      valueGot != false &&
      props.setValue(fieldname, valueGot);
    if (valueGot == "empty") {
      props.setValue(fieldname, "");
    } else if (valueGot == 0) {
      props.setValue(fieldname, 0);
    }
  }, [valueGot]);

  useEffect(() => {
    if (props.disabled == true) {
      setFieldRules({});
      if (props?.register) props?.register(fieldname, { required: false });
      if (props?.resetField && !valueGot) props?.resetField(fieldname);
    } else {
      setFieldRules(rules);
    }
  }, [props.disabled]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
        tooltip={tooltip}
       
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={fieldRules}
          render={({ field: { onChange, onBlur, ref, value } }) => (
            <AutoComplete 
            style={style}
              value={value}
              options={options}
              onSelect={(e)=> {
                props.onSelect && props.onSelect(e);
              }}
              onSearch={(e) => {
                props.onSearch && props.onSearch(e);
              }}
              onClear={(e)=>{
                props.onSelect && props.onSelect(e);
              }}
              filterOption={filterOption}
              ref={ref}
              allowClear
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              {...iProps}
              className="select-auto-dropdown"
              dropdownMatchSelectWidth={true}
            />
          )}
        />
      </Form.Item>
    </>
  );
};


export const InputNumberField = (props) => {
  const {
    label,
    rules,
    iProps,
    control,
    validate,
    fieldname,
    initValue,
    isRequired,
    validMessage,
  } = props;

  const [valueGot, setValueGot] = useState(null);
  const [fieldRules, setFieldRules] = useState({});

  useEffect(() => {
    setValueGot(props?.valueGot);
  }, [props?.valueGot]);

  useEffect(() => {
    valueGot != null &&
      valueGot != undefined &&
      valueGot != false &&
      props.setValue(fieldname, valueGot);
    if (valueGot == "empty") {
      props.setValue(fieldname, "");
    }
  }, [valueGot]);

  useEffect(() => {
    if (props.disabled == true) {
      setFieldRules({});
      if (props?.register) props?.register(fieldname, { required: false });
      if (props?.resetField && !valueGot) props?.resetField(fieldname);
    } else {
      setFieldRules(rules);
    }
  }, [props.disabled]);

  return (
    <>
      <Form.Item
        label={label}
        help={validMessage}
        validateStatus={validate}
        className={props.classes}
        required={isRequired ? isRequired : false}
      >
        <Controller
          name={fieldname}
          control={control}
          rules={fieldRules}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputNumber
              ref={ref}
              value={value ?? ""}
              onBlur={props.onBlur}
              disabled={props.disabled}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const MaskInputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={() => <MaskedInput {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const InputPassword = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          // render={(iProps)=><Input.Password {...iProps} />}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input.Password
              value={props.disabled ? "" : value}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              {...iProps}
              disabled={props.disabled}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const SelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    selectOption,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        rules={rules}
        render={() => <Select {...iProps}>{selectOption}</Select>}
      />
    </Form.Item>
  );
};


export const ReactSelectCreateAble = (props)=>{

  const [fieldRules, setFieldRules] = useState({});

  const {
    fieldname,
    isClearable,
    label,
    control,
    iProps,
    isRequired,
    tooltip,
    options,
    onCreateOption,
    setValue,
    initalValue,
    newLessor,
    setLessorInitial,
    setBenificiaryInitail
  } = props;

  useEffect(() => {
  }, [iProps?.isDisabled])

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight:'32px',
      height: "20px", // Set custom height
      display:'flex'
    }),
    placeholder: (base) => ({
      ...base,
      marginBottom:'5px',
      height: "100%", // Match the height of the control
      display: "flex",
      alignItems: "center", // Vertically center placeholder
     
    }),
    input: (base) => ({
      ...base,
      margin: "0", // Ensure no extra spacing
    }),
  };

  const onChangeData = async (e)=>{
    if(e && fieldname === 'lessorName'){
      
      let translatedText = await translateService(e?.label);
      translatedText?.translatedText && setValue("lessorNameAr",translatedText?.translatedText);
      setLessorInitial(e);
  
    }else if(!e && fieldname === 'lessorName'){
      setValue("lessorNameAr",null);
    }
    else if(e && fieldname === 'beneficiaryName'){
      e && setBenificiaryInitail(e)
    }
   
  }

  return (
    <>
      <Form.Item
        key={initalValue?.label}
        required={isRequired ? isRequired : false}
        label={label}
        className={props.classes}
        tooltip={tooltip}
      >
        <Controller
          name={fieldname}
          control={control}
          rules={fieldRules}
          render={({ field: { onChange, onBlur, ref, value } }) => (
          <CreatableSelect
            defaultValue={initalValue?.value != undefined && initalValue}
            isClearable={isClearable}
            styles={customStyles}
            isDisabled={iProps?.isDisabled}
            options={options}
            onChange={(e)=>onChangeData(e)}
            onCreateOption={(e)=> {
              onCreateOption(e)
            }}
            onBlur={props.onBlur}
            {...iProps}
            theme={(theme) => ({
              ...theme,
              borderRadius: 2,
              colors: { ...theme.colors, primary: "#767676" },
            })}
          />
          )}
        />
      </Form.Item>
    </>
  );

}

export const ReactSelectField = (props) => {
  const [fieldRules, setFieldRules] = useState({});
  const [paymentMode, setPaymentMode] = useState(null);
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    valueGot,
    validMessage,
    style,
    tooltip,
    isMulti
  } = props;

  useEffect(() => {
    valueGot && props.setValue(fieldname, valueGot);
  }, [valueGot]);

  useEffect(() => {
    if (props?.setMode) {
      ["cash", "cheque", "bank_transfer", "credit_card"]?.includes(
        paymentMode?.label
      ) && props?.setMode(paymentMode?.value);
    }
  }, [paymentMode,isRequired]);

  useEffect(() => {
    if (iProps?.isDisabled == true) {
      setFieldRules({});
      if (props?.register) props?.register(fieldname, { required: false });
      if (props?.resetField && !valueGot) props?.resetField(fieldname);
    } else {
      setFieldRules(rules);
    }
  }, [iProps?.isDisabled]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
      tooltip={tooltip}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={fieldRules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <RSelect
            ref={ref}
            value={value}
            isMulti={isMulti ? isMulti : false} 
            isSearchable={true}
            styles={{
              control: (val) => ({
                ...val,
                minHeight: 32,
                ...style,
              }),

              valueContainer: (vcontain) => ({
                ...vcontain,
                padding: "0 8px",
                textTransform: "capitalize",
              }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5 }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                backgroundColor: "#fff",
              }),
              option: (vcontain) => ({
                ...vcontain,
                textTransform: "capitalize",
              }),
              placeholder: (place) => ({ ...place, color: "rgba(0,0,0,.3)" }),
              menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
            }}
            menuPortalTarget={document.body}
            onChange={(e) => {
              setPaymentMode(e);
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            options={selectOption}
            theme={(theme) => ({
              ...theme,
              borderRadius: 2,
              colors: { ...theme.colors, primary: "#767676" },
            })}
          />
        )}
      />
    </Form.Item>
  );
}; 

export const DateField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    tooltip,
    allowClear
  } = props;

  const [valueGot, setValueGot] = useState(null);
  const [fieldRules, setFieldRules] = useState({});

  useEffect(() => {
    (props.valueGot && props.setValue) && props.setValue(fieldname, props.valueGot);

    if (props.valueGot == "empty") {
      props.setValue(fieldname, "");
    }
  }, [props.valueGot]);

  useEffect(() => {
    if (props.disabled == true) {
      setFieldRules({});
      if (props?.register) props?.register(fieldname, { required: false });
      if (props?.resetField && !props.valueGot) props?.resetField(fieldname);
    } else {
      setFieldRules(rules);
    }
  }, [props.disabled]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
        tooltip={tooltip}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={fieldRules}
          render={({ field: { onChange, value, ref } }) => (
            <DatePicker
            allowClear={allowClear === false ? false : true }
              style={{ width: "100%" }}
              value={value}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
              disabled={props.disabled}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const RangePickerField = (props) => {
  const {
    rules,
    label,
    iProps,
    control,
    validate,
    fieldname,
    initValue,
    isRequired,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);

    if (props.valueGot == "empty") {
      props.setValue(fieldname, "");
    }
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        label={label}
        help={validMessage}
        required={isRequired}
        className={props.classes}
        validateStatus={validate}
      >
        <Controller
          rules={rules}
          name={fieldname}
          control={control}
          defaultValue={initValue}
          render={({ field: { onChange, value, ref } }) => (
            <RangePicker
              ref={ref}
              value={value}
              style={{ width: "100%" }}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onCalendarChange={(e) => {
                props.onDateChange && props.onDateChange(e);
              }}
              // panelRender={(node) => (
              //   <div className="sidebar-panel">
              //     <div
              //       className="sidebar"
              //       style={{
              //         width: "300px",
              //         height: "300px",
              //         backgroundColor: "red",
              //         flexDirection: "column",
              //       }}
              //     >
              //       <p>xxx</p>
              //     </div>
              //     {node}
              //   </div>
              // )}
              // renderSidebar={() => <p>xxx</p>}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const TimeField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={() => <TimePicker style={{ width: "100%" }} {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const TimeRangeField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, value, ref } }) => (
            <TimePicker.RangePicker
              style={{ width: "100%" }}
              value={value}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputCheckbox = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    iProps,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            {...iProps}
          >
            {label}
          </Checkbox>
        )}
      />
    </Form.Item>
  );
};

export const InputSwitch = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    iProps,
    validMessage,
  } = props;

  useEffect(() => {
    // if ((props?.valueGot == false || props?.valueGot == true) && )
    props?.setValue && props?.setValue(fieldname, props?.valueGot);
  }, [props?.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, value } }) => (
          <Switch
            className="ag-switch"
            checked={value}
            // size="small"
            // handleBg="#fff"
            onChange={(e) => {
              onChange(e);
            }}
            {...iProps}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        )}
      />
    </Form.Item>
  );
};

export const UploadField = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    filelist,
    fileProps,
    iProps,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, value } }) => (
          <Upload {...fileProps}>
            <Input
              {...iProps}
              value={
                filelist && filelist[0] && filelist[0].name
                  ? filelist[0].name
                  : "Choose a file to upload"
              }
              addonAfter="Browse"
              allow="csv"
            />
          </Upload>
        )}
      />
    </Form.Item>
  );
};

export const TextAreaField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ""}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Input.TextArea
            value={props.disabled ? "" : value}
            ref={ref}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            disabled={props.disabled}
          />
        )}
      />
    </Form.Item>
  );
};

export const InputRadio = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    noStyle,
    disabled,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);
  return (
    <Form.Item
      label={label}
      noStyle={noStyle}
      help={validMessage}
      valuePropName="checked"
      validateStatus={validate}
      required={isRequired ? isRequired : false}
      className={props.class ? props.class : ""}
    >
      <Controller
        rules={rules}
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, value } }) => (
          <Radio.Group
            value={value}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value);
              props.onChange && props.onChange(e);
            }}
          >
            {props.options}
          </Radio.Group>
        )}
      />
    </Form.Item>
  );
};
