import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../atoms/Popup";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Space,
  Radio,
  message,
  Tag,
  Tooltip,
} from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { getPresignedImage, uploadDocumentMedia } from "../../../utils/media";
import PdfCascadeView from "../PDFViewer/pdfCascadeView";
import { useSearchHistory } from "../../../utils/filterHelper";
import axios from "axios";
import { leaseService } from "../../../configs/constants";
import {
  ADDITIONAL_DOCUMENT,
  TENANCY_CONTRACT,
  getDocumentKey,
} from "../../../configs/documents";
import PdfView from "../PDFViewer/pdvViewNew";
import { Delete, DeleteIcon } from "../../../assets/svg";
import { getBookingDetail } from "../../modules/BookingForm/ducks/actions";
import { useDispatch } from "react-redux";

type documentType = {
  label: String;
  key: String;
  documentSent: Boolean;
  isSign: Boolean;
};

const GeneratedDoc = (props) => {
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);

  const dispatch: any = useDispatch();
  const { title, data, documentId, LEASEID, onRemoveDocument, sendForEsignature } = props;

  const [document, setDocument] = useState<documentType>({
    label: "",
    key: "",
    documentSent: false,
    isSign: false,
  });

  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState(null);
  const [hasMounted, setHasMounted] = useState(false);

  const [status, setStatus] = useState("");

  const [openFileView, setOpenFileView] = useState(false);

  useEffect(() => {
    if (data) {
      const doc: documentType = {
        label: data?.documentName,
        key: getDocumentKey(data?.documentName),
        documentSent: data?.sendToTenant,
        isSign: data?.signaturesPlaceholder,
      };
      setDocument(doc);
    }
  }, [data]);

  const updateDocument = async (values) => {
    const { file, signBy } = values;
    const payload = {
      documentId: documentId,
      signBy: signBy,
      requestFrom: "portal",
      type: "pdf",
      documentType: data?.documentName,
    };
    if (file) {
      const { data } = await uploadDocumentMedia({ file_asset: file });
      if (data?.result?.documentId) {
        payload.documentId = data?.result?.documentId;
        await updateDocumentAPI(payload);
      }
    } else {
      await updateDocumentAPI(payload);
    }
  };

  const updateDocumentAPI = async (payload) => {
    let endpt = "booking";
    if (data?.isLeasingDoc == true)
      endpt = "leasing";
    try {
      const url = `${leaseService}/${endpt}/updateSignDocument/${LEASEID}`;
      await axios.patch(url, payload);
      message.success("Successfully updated document");
      onClosePopUp();
      await dispatch(getBookingDetail(LEASEID));
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.error);
    }
  };

  const onClosePopUp = () => {
    setVisible(false);
  };

  const onOpenPopUpHandler = () => {
    setTrigger(1);
    setVisible(true);
  };

  const getUrl = async (data) => {
    const url = await getPresignedImage([data]);
    if (url && url.length > 0) {
      setViewOrDownloadUrl(url[0]);
    }
  };

  const downloadUrl = async () => {
    const url = await getPresignedImage([documentId], false);
    if (url && url.length > 0) {
      window.location.href = url[0];
    }
  };

  useEffect(() => {
    if (hasMounted && viewOrDownloadUrl) {
      setOpenFileView(true);
    } else {
      setHasMounted(true);
    }
  }, [viewOrDownloadUrl, hasMounted]);

  useEffect(() => {
    let status = "";
    const signBy = data?.signBy;
    const sendToTenant = data?.sendToTenant;
    if (data?.signaturesPlaceholder == true) {
      if (signBy && signBy === "both") status = "Signed By Both";
      else if (signBy && signBy === "landlord") status = "Signed By Landlord";
      else if (signBy && signBy === "tenant") status = "Signed By Tenant";
      else if (sendToTenant === true) status = "Awaiting Tenant Signature";
      else status = "";
    }
    setStatus(status);
  }, [data?.signBy]);

  const sendDocument = () => {
    if (sendForEsignature && document.key) sendForEsignature(document.key);
  };

  const popup = [
    {
    
      content: (
        <Image
          preview={false}
          width="100%"
          style={{ padding: "20px" }}
          src={viewOrDownloadUrl}
        />
      ),
      width: 650,
      onCancel: onClosePopUp,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          {title ?? "Generated document"}
        </Title>
      ),
      content: (
        <PdfCascadeView
          pdf={viewOrDownloadUrl}
          downloadUrl={downloadUrl}
          signBy={data?.signBy}
          documentName={data?.documentName}
          sendForEsignature={sendDocument}
          documentSent={document?.documentSent}
          placeholder={document?.isSign}
          onSubmit={updateDocument}
          documentType ={document?.key}
          setTrigger={setTrigger}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <Fragment >
      <Card  bordered={false} className="document-card">
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12} style={{ display: "flex" }}>
            <FileTextOutlined className="require-doc" />
            <Title level={5}>{`${title}`}</Title>
          </Col>

          <Col span={12}>
       
            <Row>
              <Col span={2}></Col>
              <Col
                span={10}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "20px",
                }}
              >
                <Space size={20}>
                  <Tooltip title="Edit" trigger="hover" showArrow={false}>
                    {data?.handleAction && (
                      <Button
                        type="link"
                        className="ef-action-btn"
                        onClick={() => { data?.handleAction() }} //handleAction for attachDocs
                      >
                        <EditOutlined />
                      </Button>
                    )}

                    {!data?.handleAction && (
                      <Button
                        type="link"
                        className="ef-action-btn"
                        onClick={onOpenPopUpHandler}
                      >
                        <EditOutlined />
                      </Button>
                    )}
                  </Tooltip>
                
                  <Tooltip title="Download" trigger="hover" showArrow={false}>
                    <Button
                      type="link"
                      className="ef-action-btn"
                      onClick={async () => downloadUrl()}
                    >
                     
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                  {/* <Space> */}
                  <Tooltip title="View" trigger="hover" showArrow={false}>
                    <Button
                      type="link"
                      className="ef-action-btn"
                      onClick={async () => await getUrl(documentId)}
                    >
                      <EyeOutlined />
                    </Button>
                  </Tooltip>
                  {data?.remove && (
                    <Tooltip title="Delete" trigger="hover" showArrow={false}>
                      <Button
                        type="link"
                        className="ef-action-btn"
                        onClick={onRemoveDocument}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </Col>
              <Col span={12}>
                {status && <Tag className="ef-tag tag-pending">{status}</Tag>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Popup {...popupProps} destroyOnClose={true} />
      <PdfView
        pdfData={viewOrDownloadUrl}
        openFileView={openFileView}
        setOpenFileView={setOpenFileView}
      />
    </Fragment>
  );
};

export default GeneratedDoc;
