import React, { useState, useEffect ,useRef,useLayoutEffect} from "react";
import { AgGridReact } from "ag-grid-react";
import { Card } from "antd";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";


const Grid = (props) => {
  const {
    filterGrid,
    data,
    columns,
    pagination,
    defaultSettings,
    noRowlabel,
    noRowbtnAction,
    refreshAction,
    fullPage,
    rowSelection = "single",
    editType = "",
    onSelectionChanged,
    onCellValueChanged,
    suppressRowClickSelection = true,
    deselectAll,
    overlayNoRowsTemplate = "",
    autoSizeColumns,
    className,
    gridClassName = "",
    rowHeight,
    domLayout,
    height
  } = props;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [noRow, setNoRow] = useState(false);
  const gridRef:any = useRef();
  const isStateApplied = useRef(false);

  const autosize = () => {
    const columns = gridColumnApi?.getColumns();

    const allColumnIds = [];
    columns?.forEach((column) => {
      allColumnIds?.push(column?.colId);
    });
    gridColumnApi?.autoSizeColumns(allColumnIds, false);
  };



  useEffect(() => {
  
    if (data?.length > 0) {
      setRowData(data);

      data?.length ? setNoRow(false) : setNoRow(true);
    } else {
   
      setRowData(null);
    }
  }, [data]);

  useEffect(() => {
    if (autoSizeColumns && gridColumnApi && data?.length > 0) {
      setTimeout(() => {
        autosize();
      }, 300);
    }
  }, [gridColumnApi, data]);

  useEffect(() => {
    gridApi && gridApi.setQuickFilter(filterGrid);
  }, [filterGrid]);

  useEffect(() => {
    refreshAction ? refreshCells() : null;
  }, [refreshAction]);
  
  const refreshCells = () => {
    var params = {
      force: true,
      suppressFlash: true,
    };
    gridApi && gridApi.refreshCells(params);
  };

  useEffect(() => {
    gridApi?.deselectAll();
  }, [deselectAll]);


  function onGridReady(params) {
    gridRef.current = params.columnApi;
 
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  
  }

// Save column state when columns are reordered or resized
const onColumnStateChanged = () => {
  if (gridColumnApi) {
    const columnState = gridColumnApi.getColumnState();
    localStorage.setItem("ag-grid-column-state", JSON.stringify(columnState));
  }
};
// Retrieve saved column state from localStorage on load
// useEffect(() => {
//   if (gridColumnApi && !isStateApplied.current) {
//     const savedState = localStorage.getItem("ag-grid-column-state");
//     if (savedState) {
//       gridColumnApi.applyColumnState({
//         state: JSON.parse(savedState),
//         applyOrder: true,
//       });
//     }
//     isStateApplied.current = true;  // Mark the state as applied
//   }
// }, [gridColumnApi]);


  return (
    <div className={`custom-style ${className}`}>
      <div
        id="myGrid"
        style={height ? { height:height, width: "100%" }  : { height: "215px", width: "100%" }}
        className={`ag-theme-material ${
          fullPage && data?.length ? "fp-height" : ""
        } ${noRow ? "h-auto" : ""}`}
      >
        <AgGridReact
   
          ref={gridRef}
          // autoSizeStrategy={autoSizeCell}
          defaultColDef={{...defaultSettings}}
          className={gridClassName}
          colResizeDefault={"shift"}
          suppressDragLeaveHidesColumns={true}
          pagination={pagination}
          animateRows={true}
          columnDefs={columns}
          rowData={rowData}
          editType={editType}
          rowHeight={rowHeight}
        //  domLayout={domLayout ? "autoHeight" : 'none' }
          onColumnMoved={()=>onColumnStateChanged()} // Column reordered
          // onColumnResized={onColumnStateChanged} // Column resized
          // onColumnPinned={onColumnStateChanged}  // Column pinned/unpinned
          rowSelection={rowSelection}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={onCellValueChanged}
          suppressRowClickSelection={suppressRowClickSelection}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
        />
      </div>
      {noRow && overlayNoRowsTemplate === "" && (
        <Card bordered={false}>
          <EmptyPlaceholder label={noRowlabel} action={noRowbtnAction} />
        </Card>
      )}
    </div>
  );
};

export default Grid;
