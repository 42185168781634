import React, { useEffect, useState } from "react";
import { Row, Card, Typography, Form, Button, Divider, message } from "antd";
import { useSelector,useDispatch } from "react-redux";
import { propertyService } from "../../../../../configs/constants";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { getConfigData,getPropertyBeneficiaries} from "../../ducks/actions";
import { useForm } from "react-hook-form";
import axios from "axios";
import General from "../../AddProperty/components/AddInformation/General";
import ContractInfo from "../../AddProperty/components/AddInformation/ContractInfo";
import Address from "../../AddProperty/components/AddInformation/Address";

const { Title } = Typography;

var _ = require("lodash");

export default ({ propertyDetails,setNewLessor,newLessor }) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [noEdit, setNoEdit] = useState(true); 
  const [properyBeneficieries, setPropertyBeneficieries] = useState([]); 
  const [propertyLessor, setPropertyLessor] = useState([]); 
  const [comm, setComm] = useState(false);
  const [lessorInitial, setLessorInitial] = useState(null); 
  const [benificiaryInitial, setBenificiaryInitail] = useState(null);

  const apiUnitTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitType
  );
  
  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType
  );

  const usageWatch = watch("usage_type_id");
  const dispatch: any = useDispatch();
  const configKeys = ["locationlist", "emiratesList", "nationalityList"];

  const getPropertyBeneficiariesData = async()=>{
      
    try{
      let data = await getPropertyBeneficiaries();
   
      data?.lessor?.length > 0 && setPropertyLessor(data?.lessor);
      let lessorData = propertyDetails?.lessorId && data?.lessor?.find((lessor)=> lessor?.lessorId === propertyDetails?.lessorId)
     
      lessorData && setLessorInitial({label:lessorData?.lessorName,value:lessorData?.lessorId})
      
      data?.beneficiary?.length > 0 && setPropertyBeneficieries(data?.beneficiary)
      let beniData = propertyDetails?.beneficiaryData?.beneficiaryId && data?.beneficiary?.find((beneficiary)=> beneficiary?.beneficiaryId === propertyDetails?.beneficiaryData?.beneficiaryId);
      beniData && setBenificiaryInitail({value:beniData?.beneficiaryId,label:beniData?.beneficiaryName})
    
    }catch(error){
    console.log('error getting beneficiary',error)
    }
   
  }


  useEffect(() => {
    if (usageWatch) {
   
      if (usageWatch.value == 2) {
        setComm(true);
      } else {
        setComm(false);
      }
    }
  }, [usageWatch]);

  useEffect(() => {
    dispatch(getConfigData(configKeys.join(",")));
  }, []);

  useEffect(() => {
    if (apiUnitTypeData.length) {
      let unitlist = apiUnitTypeData.find(
        (item) => item.id === propertyDetails.unit_type_id
      );
      if (unitlist) {
        setValue("unit_type_id", { value: unitlist.id, label: unitlist.name });
      }
    }
  }, [apiUnitTypeData]);

  useEffect(() => {
    if (apiUnitSubTypeData.length) {
      let sublist: any = {};
      sublist = apiUnitSubTypeData.find(
        (item) => item.id === propertyDetails.unit_subtype_id
      );
      if (sublist) {
        setValue("unit_subtype_id", { value: sublist.id, label: sublist.name });
      } else {
        setValue("unit_subtype_id", "");
      }
    }
  }, [apiUnitSubTypeData]);

  useEffect(() => {
    if (!noEdit) {
      let featureSelected = [];
      let amenitySelected = [];
      setValue("property_features", featureSelected);
      setValue("property_amenities", amenitySelected);
    }
  }, [noEdit]);

  useEffect(()=>{
    getPropertyBeneficiariesData();
  },[propertyDetails]);


// mobile number have 971 code remove it 

  function removeCountryCode(phoneNumber) {
    if(phoneNumber.startsWith("971-")) {
        return phoneNumber.slice(4); // Remove the first 4 characters (971-)
    }
    return phoneNumber;
}


  useEffect(() => {
    if (Object.keys(propertyDetails).length>0) {

      {/* start adding these fields after story 9873 in sprint 41 and 42 */}

      setValue("makaniNumber", propertyDetails?.makaniNumber);
      setValue("landNnumber", propertyDetails?.landNnumber);
      setValue("DMNumber", propertyDetails?.DMNumber);
      setValue("alternatePropertyName", propertyDetails?.alternatePropertyName);

      {/* End adding these fields after story 9873 in sprint 41 and 42 */}

      setValue("buildingName", propertyDetails?.buildingName);
      setValue("propertyReferenceNumber",propertyDetails.propertyReferenceNumber);
      setValue("businessUnitName", propertyDetails.businessUnitName);
      setValue("propertyCode", propertyDetails.propertyCode);
      setValue("ownerName", propertyDetails?.ownerName);
      
      if(propertyDetails?.erpLaName)
        {
        setValue("propertyAdmin", {value:propertyDetails?.propertyAdmin,label: propertyDetails?.erpLaName});
        }
      
      if(propertyDetails?.ownerNationality)
      {
        setValue("ownerNationality", {value:propertyDetails?.ownerNationality,label: propertyDetails?.ownerNationality});
      }
      setValue("addressLine1", propertyDetails.address?.addressLine1);
      setValue("emirates", {value: propertyDetails.address?.emirates,label:propertyDetails.address?.emirates});
      setValue("addressLine2", propertyDetails.address?.addressLine2);
      setValue("country", {value: propertyDetails.address?.country, label: propertyDetails.address?.country});
      let long = Number(propertyDetails.address?.longitude);
      let lat = Number(propertyDetails.address?.latitude);
      long && setValue("longitude", long);
      lat && setValue("latitude", lat);
      

      if (propertyDetails.address?.community) {
        setValue("community", {
          value: propertyDetails.address?.community,
          label: propertyDetails.address?.community,
        });
      }
      
    }

    // if lessor and beneficiary exist 
    if(propertyDetails?.ownerNumber){
      setValue("ownerNumber",propertyDetails?.ownerNumber);
    }
    if(propertyDetails?.lessorData)
    {
      
      propertyDetails?.lessorData?.lessorLetterFax &&  setValue('lessorFax',propertyDetails?.lessorData?.lessorLetterFax);
      propertyDetails?.lessorData?.lessorNameAr && setValue("lessorNameAr",propertyDetails?.lessorData?.lessorNameAr);
      propertyDetails?.lessorData?.lessorEmail && setValue("lessorEmail",propertyDetails?.lessorData?.lessorEmail);
      propertyDetails?.lessorData?.lessorLetterPOBox && setValue("lessorPOBox",propertyDetails?.lessorData?.lessorLetterPOBox);
      propertyDetails?.lessorData?.lessorTel && setValue("lessorPhone",removeCountryCode(propertyDetails?.lessorData?.lessorTel),{ shouldValidate: true, });
      propertyDetails?.lessorData?.lessorLicenseNo &&  setValue('lessorLicenseNo',propertyDetails?.lessorData?.lessorLicenseNo);
      propertyDetails?.lessorData?.lessorAddress &&  setValue('lessorAddress',propertyDetails?.lessorData?.lessorAddress);
      
    }
  }, [propertyDetails]);


  const onSubmit = async (val) => {

    const date = val?.lessorLicenseExpiry && dayjs(new Date(val?.lessorLicenseExpiry.$y, val?.lessorLicenseExpiry.$M, val?.lessorLicenseExpiry.$D, val?.lessorLicenseExpiry.$H, val?.lessorLicenseExpiry.$m, val?.lessorLicenseExpiry.$s, val?.lessorLicenseExpiry.$ms));
    
    const lessorLicenseExpiry = date && date.format("YYYY-MM-DD");

    const postJson = {


      propertyAdmin:val?.propertyAdmin?.value,
      erpLaName	:val?.propertyAdmin?.label,
      erpLaEmail:val?.propertyAdmin?.email,

      address:{
        addressLine1: val.addressLine1,
        addressLine2: val.addressLine2,
        emirates: val.emirates.value,
        country: val.country.value,
        community: val.community.value,
        latitude: val.latitude,
        longitude: val.longitude,
      },
      // after story 9783 adding these fields
      ownerName: val?.ownerName,
      ownerNationality: val?.ownerNationality?.value,
      ownerNumber:val?.ownerNumber,
      alternatePropertyName:val?.alternatePropertyName,
      landNnumber:val?.landNnumber,
      DMNumber:val?.DMNumber,
      makaniNumber:val?.makaniNumber,
      beneficiary: {
        beneficiaryId:benificiaryInitial?.value
    },
    lessor: {
        lessorId:lessorInitial?.value,
        lessorNameAr: val?.lessorNameAr,
        lessorLicenseNo:val?.lessorLicenseNo,
        lessorLicenseExpiry: lessorLicenseExpiry,
        lessorTel: val?.lessorPhone,
        lessorEmail: val?.lessorEmail,
        lessorAddress: val?.lessorAddress,
        lessorLetterPOBox: val?.lessorPOBox,
        lessorLetterFax: val?.lessorFax
    },
      
    };

    try {
      await axios.patch(
        `${propertyService}/property/${propertyDetails.propertyId}`,
        postJson
      );
      setNewLessor(!newLessor)
      message.success("Property Details Updated");
      setNoEdit(true);
     await getPropertyBeneficiariesData()
    } catch (e) {
      const { response } = e;
      message.error(response.data.error);
      setNoEdit(true);
    }
  };

  // console.log(lessorInitial?.value,'lessorInitial')
  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        onFinish={handleSubmit(onSubmit)}
      >
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              General Information
            </Title>
          }
          extra={[
            <Button
              key={1}
              type="link"
              className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
              icon={<CloseCircleOutlined />}
              onClick={() => setNoEdit(true)}
              htmlType="button"
            >
              Cancel
            </Button>,
            <Button
              key={2}
              type="link"
              className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
              icon={<CheckCircleFilled />}
              htmlType="submit"
            >
              Save
            </Button>,
            <Button
              key={3}
              type="link"
              className={`p-0 no-border no-shadow ${!noEdit && "d-none"}`}
              icon={<EditOutlined />}
              onClick={() => setNoEdit(false)}
              htmlType="button"
            >
              Edit
            </Button>,
          ]}
        >
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            noEdit={noEdit}
          />
        </Card>

        <Divider className="m-0" />

        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              Contract Information
            </Title>
          }
        
        >
          <ContractInfo
            control={control}
            errors={errors}
            setLessorInitial={setLessorInitial}
            setBenificiaryInitail={setBenificiaryInitail}
            propertyId = {propertyDetails.propertyId}
            noEdit={noEdit}
            setValue={setValue}
            setNewLessor={setNewLessor}
            newLessor={newLessor}
            propertyBeneficieries = {properyBeneficieries}
            propertyLessor={propertyLessor}
            lessorLicenseExpiry = {propertyDetails?.lessorData?.lessorLicenseExpiry}
            lessorInitial={lessorInitial}
            benificiaryInitial= {benificiaryInitial}
            lessorNameArabic = {propertyDetails?.lessorData?.lessorNameAr}
          />
        </Card>
        <Divider className="m-0" />
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              Property Information
            </Title>
          }
        >
          <Row gutter={24}>
            <Address
              control={control}
              errors={errors}
              setValue={setValue}
              noEdit={noEdit}
            />
          </Row>
          
        </Card>
      </Form>
    </>
  );
};
