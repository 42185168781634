import React from "react";
import { Row, Col, message } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  DateField,
  InputField,
  ReactSelectField,
  ReactSelectCreateAble
} from "../../../../../atoms/FormElement";
import { propertyService } from "../../../../../../configs/constants";
import { Calendar } from "../../../../../../assets/svg";
import { dateFormat, datePlaceholderddmmyyFormat } from "../../../../BookingForm/components/utils";
import { translateService } from "../../../../BookingForm/ducks/services";

var _ = require("lodash");

export default (props) => {

  const {
    control,
    errors,
    noEdit,
    setValue,
    lessorLicenseExpiry,
    propertyBeneficieries,
    propertyLessor,
    lessorInitial,
    setLessorInitial,
    propertyId,
    newLessor,
    setNewLessor,
    benificiaryInitial,
    setBenificiaryInitail,
    lessorNameArabic
  } = props;

  const configData = useSelector((state: any) => state.dashboard.configData);

  

  const handleCreate = async (inputValue, type) => {

    let postJson = {};
    if (type === 1) {


     let translatedText = await translateService(inputValue);
     translatedText?.translatedText && setValue("lessorNameAr",translatedText?.translatedText);
      postJson = {
        lessor: {
          lessorName: inputValue,
          lessorNameAr:translatedText?.translatedText
        },
        beneficiary: {}
      };

    } else {
      postJson = {
        beneficiary: {
          beneficiaryName: inputValue
        },
        lessor: {
          // lessorName: inputValue
        },
      };
    }

    try {
      let result = await axios.patch(
        `${propertyService}/property/${propertyId}`,
        postJson
      );
      message.success(type === 1 ? "Lessor Created Please Save for Update " : '"Beneficiary Created Please Save for Update');
      type === 1 && setLessorInitial({ value: result?.data?.result?.lessorId, label: inputValue });
      type === 2 && setBenificiaryInitail({ value: result?.data?.result?.beneficiaryId, label: inputValue });
      setNewLessor(!newLessor);
    } catch (e) {
      const { response } = e;
      message.error(response.data.error);
    }
  };

  const onChangeData = async (e)=>{
    if(e){
      console.log('beneficiaryName',e)
      let translatedText = await translateService(e?.label);
      translatedText?.translatedText && setValue("lessorNameAr",translatedText?.translatedText);
    
  
    }else{
      console.log('beneficiaryName',e)
      e && setBenificiaryInitail(e)
    }
   
  }
  return (
    <Row gutter={24}>


      <Col span={6}>

        <ReactSelectField
          isRequired={false}
          label="Owner Nationality"
          fieldname="ownerNationality"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          setValue={setValue}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(configData?.nationalityList, (item) => ({
            value: item,
            label: item,
          }))}

        />
      </Col>

      <Col span={6}>

        <InputField
          isRequired={false}
          label="Owner Name"
          fieldname="ownerName"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Owner Name",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>


      <Col span={6}>
        <InputField
          isRequired={false}
          label="Owner Number"
          fieldname="ownerNumber"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Owner Number",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>

      {


        <Col span={6}>
          <ReactSelectCreateAble
            isClearable={true}
            options={_.map(propertyLessor, (item) => ({
              value: item?.lessorId,
              label: item?.lessorName,
            }))}
            // onChange={handleChange}
            initalValue={lessorInitial}
            isRequired={false}
            label="Lessor Name"
            fieldname="lessorName"
            control={control}
            setValue={setValue}
            newLessor={newLessor}
            setLessorInitial={setLessorInitial}
            onCreateOption={(e) => handleCreate(e, 1)}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            iProps={{
              placeholder: "Select one",
              isDisabled: noEdit,
              className: `${noEdit ? "disablerselect" : ""}`,
            }}
          />
        </Col>}


      <Col span={6}>
        <InputField
          isRequired={false}
          fieldname="lessorEmail"
          label="Lessor Email"
          control={control}
          rules={{
            pattern: {
              value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter valid Email Address",
            },
            // required: "Please enter Email Address",
          }}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          iProps={{
            placeholder: "user@gmail.com",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,

          }}
          initValue=""
          validate={errors.lessorEmail && "error"}
          validMessage={
            errors.lessorEmail && errors.lessorEmail.message
          }
        />
      </Col>
      <Col span={6}>

        <InputField
          isRequired={false}
          label="Lessor Name (Arabic)"
          fieldname="lessorNameAr"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Lessor Name (Arabic)",
            // readOnly: true,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
        />

      </Col>

      <Col span={6}>

        <DateField
          control={control}
          isRequired={false}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          disabled={noEdit}
          setValue={setValue}
          label="Lessor License Expiry"
          fieldname="lessorLicenseExpiry"
          validate={errors?.lessorLicenseExpiry && "error"}
          valueGot={dateFormat(lessorLicenseExpiry)}
          validMessage={errors?.lessorLicenseExpiry && errors?.lessorLicenseExpiry.message}
          iProps={{
            format: datePlaceholderddmmyyFormat, 
            placeholder: datePlaceholderddmmyyFormat,
            suffixIcon: <Calendar />,
            
          }}
        />

      </Col>


      <Col span={6}>
        <ReactSelectCreateAble
          isClearable={true}
          options={_.map(propertyBeneficieries, (item) => ({
            value: item?.beneficiaryId,
            label: item?.beneficiaryName,
          }))}
          initalValue={benificiaryInitial}
          // onChange={handleChange}
          setValue={setValue}
          isRequired={false}
          label="Beneficiary Name"
          fieldname="beneficiaryName"
          setBenificiaryInitail={setBenificiaryInitail}
          control={control}
          onCreateOption={(e) => handleCreate(e, 2)}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}


        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Lessor Phone"
          fieldname="lessorPhone"
          control={control}
          validate={errors.lessorPhone && "error"}
          validMessage={
            errors.lessorPhone && errors.lessorPhone.message
          }
          initValue={''}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          rules={{
            minLength: {
              value: 9,
              message:
                "Please enter valid Phone number, 9 digits required",
            },
            maxLength: {
              value: 10,
              message:
                "Phone number can not be more than 10 digits",
            },
            // required: "Please enter Phone No.",
            setValueAs: (value) => `${value}`,
          }}
          disabled={noEdit}
          iProps={{
            readOnly: noEdit,
            placeholder: "Add Phone Number",
            addonBefore: "+971",
            type: "text",
            className: `${noEdit ? "noEdit" : "apperance"}`,
          }}
        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Lessor License No"
          fieldname="lessorLicenseNo"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Lessor License No ",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Lessor PO Box"
          fieldname="lessorPOBox"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Lessor PO Box",
            readOnly: noEdit,
            type: "text",
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Lessor Fax"
          fieldname="lessorFax"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Lessor Fax",
            readOnly: noEdit,
            type: "text",
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Lessor Address"
          fieldname="lessorAddress"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Lessor Address ",
            readOnly: noEdit,
            // type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}

        />
      </Col>

    </Row>
  );
};
