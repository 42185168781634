import React from 'react';
import { DatePicker, Form, Row, Col, Button } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { updateBooking } from '../../ducks/actions';
import { PopupConfirm } from "../../../../atoms/Popup";
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { message } from 'antd'
import { useParams } from "react-router-dom";
import { getBookingDetail } from '../../ducks/actions';
import { TextAreaField ,DateField} from '../../../../atoms/FormElement';
import { useForm } from "react-hook-form";
const index = ({ bookingExpiryDate, requestExtension }) => {

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();
  let defaultDate = dayjs(bookingExpiryDate);
  const dispatch = useDispatch();
  const { leaseId = "" } = useParams();
  const thunkDispatch: ThunkDispatch<true, void, AnyAction> = dispatch;


  const confirmBox = (comment,formattedDate) => {
    PopupConfirm({
      title: `Are you sure you want to update the booking expiry date?.`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {

        try {
          // Use ThunkDispatch to type your dispatch
          const response = await thunkDispatch(updateBooking({ leaseId, body: { "onlyBooking": true, "bookingExtensionDate": formattedDate,comment } }));

          if (response?.data?.code === 200) {
            message.success('Your request to extend booking has been sent to a manager');
            await thunkDispatch(getBookingDetail(leaseId))
            requestExtension(false);

          }
        } catch (error) {

          if (error?.response?.data?.success === false) {
            message.error(error?.response?.data?.error)
          }
        }
      },
    });
  }
  const getExpiryDate = (values) => {


     const date = dayjs(new Date(values?.getExpiry?.$y, values?.getExpiry?.$M, values?.getExpiry?.$D, values?.getExpiry?.$H, values?.getExpiry?.$m, values?.getExpiry?.$s, values?.getExpiry?.$ms));

     // Format the date as "YYYY-MM-DD"
      const formattedDate = date.format("YYYY-MM-DD");
   
    //  convert to days object just for comparison
    // date.isSame(defaultDate, 'day') ||
    if (formattedDate == "") {
         return false;
    } else {
      confirmBox(values?.comment,formattedDate);
    }

  };
  // Define the custom start date
  const startDate = moment(bookingExpiryDate);

  const disabledDate = (current) => {
    // Calculate seven days from the start date
    const sevenDaysLater = startDate.clone().add(6, 'days').endOf('day');

    // Disable dates before the start date or after seven days from the start date
    return current && (current < startDate || current > sevenDaysLater);
  };


  return (
   


    <Form layout="vertical" onFinish={handleSubmit(getExpiryDate)}>
      <Row gutter={24}>

        <Col span={12}>
        


          <DateField
             

            control={control}
            label={`Select New Booking End Date`}
            setValue={setValue}
            allowClear={false}
            fieldname={`getExpiry`}
            initValue={defaultDate}
            iProps={{
              className: "cd",
              disabledDate: disabledDate,
            }}
            />
    </Col>


        <Col span={24}>
          <TextAreaField
            label={`Enter a comment`}
            fieldname="comment"
            iProps={{ placeholder: "Some Comment...." }}
            initValue=""
           setValue={setValue}
           control={control}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={()=> requestExtension(false)}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ef-approve-btn" htmlType="submit" >
          Confirm
          </Button>  
        </Col>
      </Row>
    </Form>
  )
}
export default index;