import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Space,
  Button,
  Badge,
  Typography,
} from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  BellOutlined,
  LogoutOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { clearFilters } from "../../../app/modules/Units/ducks/actions";
import { clearRenewalFilters, clearMoveoutFilters } from "../../../app/modules/Contracts/ducks/actions";
import { deleteCookie } from "../../../utils/helper";
import storeLogo from "../../../assets/img/eqari-logo.svg";
import {
  getNotifications,
  getNewNotifications,
} from "../../modules/Notification/ducks/action";
import NotificationPanel from "../NotificationPanel";
import { getPropertiesDrop } from "../../modules/Properties/ducks/actions";
import SubMenu from "antd/es/menu/SubMenu";
import { authService } from "../../../configs/constants";
import axios from "axios";

const { Header } = Layout;
const { Text } = Typography;

const HeaderBar = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState();
  const [newNotifications, setNewNotifications] = useState();

  let notificationInterval = null;

  const logoutHandler = async () => {

 

    const url = `${authService}/logout`;
    
    try {
      dispatch(clearFilters());
      dispatch(clearRenewalFilters());
      dispatch(clearMoveoutFilters());
      dispatch(getPropertiesDrop([]));
      const response = await axios.post(url);
      
      if (response?.data?.success || response?.data?.code === 401) {
        window.localStorage.clear();
        deleteCookie("landlordUser");
        navigate("/login");
      }
    } catch (error) {
      window.localStorage.clear();
      deleteCookie("landlordUser");
      navigate("/login");
    }
  };



  const notificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNotifications
  );

  const newNotificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNewNotifications
  );

  useEffect(() => {
    // if (JSON.parse(window.localStorage.getItem("login-response"))?.token) {
    dispatch(getNotifications());
    dispatch(getNewNotifications());
    // }
  }, []);

  useEffect(() => {
    if (notificationsData) {
      setNotifications(notificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    if (newNotificationsData) {
      setNewNotifications(newNotificationsData);
    }
  }, [newNotificationsData]);

  useEffect(() => {
    notificationInterval = setInterval(() => {
      // dispatch(getNotifications());
      // dispatch(getNewNotifications());
    }, 1000 * 60);
    return () => {
      clearInterval(notificationInterval);
    };
  }, []);
  const menu = (
    <Menu>
      <SubMenu key="Maintenance" icon={<ContactsOutlined />} title="Contact Us">
        <Menu.Item key="getHelp">
          <Link to={"/contactus"} state={{ type: "Get help" }}>
            Get help
          </Link>
        </Menu.Item>
        <Menu.Item key="reportProblem">
          <Link to={"/contactus"} state={{ type: "Report a problem" }}>
            Report a problem
          </Link>
        </Menu.Item>
        <Menu.Item key="requestFeature">
          <Link to={"/contactus"} state={{ type: "Request a feature" }}>
            Request a feature
          </Link>
        </Menu.Item>
        <Menu.Item key="requestDeactivation">
          <Link to={"/contactus"} state={{ type: "Request deactivation" }}>
            Request deactivation
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout 
        </Button>
      </Menu.Item>
    </Menu>
  );

  const Notification: any = NotificationPanel;

  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image
            src={storeLogo}
            preview={false}
            width={150}
            alt="EQARCOM logo"
            style={{ marginTop: 5,marginLeft: "-5px" }}
          />
        </Col>
        <Col flex="auto">
          <Space size={12}>
            {/* <Space size={0}> */}
            {/* <Button
                icon={<CalendarOutlined />}
                className="headerIcon-btn p-0"
                type="link"
                onClick={() => navigate("/notifications/calendar")}
              /> */}
            <Dropdown
              overlay={
                <Notification
                  notificationsData={notifications}
                  newNotificationsData={newNotifications}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              arrow
            >
              <Button type="link" className="headerIcon-btn" size="large">
                <Badge {...(newNotificationsData?.data?.length && { dot: true })}>
                  <BellOutlined />
                </Badge>
              </Button>
            </Dropdown>
            {/* </Space> */}
            <Dropdown overlay={menu} placement="bottomRight">
              <Space>
                <Avatar
                  size={24}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#184461", color: "#fff" }}
                />
                <Text>
                  {
                    JSON.parse(window.localStorage.getItem("login-response"))
                      ?.email
                  }
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
