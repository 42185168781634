import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Upload,
  Space,
  Typography,
  message,
  Spin,
  Radio,
} from "antd";
import Title from "antd/es/typography/Title";
import { useForm, useWatch, Controller } from "react-hook-form";
import {
  InputField,
  TimeField,
  DateField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  // getTicketType,
  // getTicketSubType,
  // getPropertyName,
  // getTechnicians,
  // resetTechnician,
  // getPropertyUnits,
  // getAvailablity,
} from "../../ducks/actions";
import { maintenanceService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  uniquiFileName,
  getSingleUpload,
  getSingleTicketMediaUpload,
} from "../../../../../utils/fileUpload";
import { dateByformat } from "../../../../../utils/dateHelper";
import { getMaintenanceTypes } from "../../../Configurations/ducks/actions";
import { getServiceProviders } from "../../../ServiceProvider/ducks/actions";
import { getPropertiesDrop } from "../../../Properties/ducks/actions";
import dayjs from "dayjs";
import { Popup } from "../../../../atoms/Popup";

var _ = require("lodash");
const { Text } = Typography;

const defaultVal = {
  title: "",
  subject: "",
  description: "",
  property: "",
  deliveryDate: "",
  deliveryTime: "",
  unitCode: "",
  audienceType: 'all',
  appAknowledgement: "yes"
};


const modifyNotice = (props) => {

  const [deliveryMedium, setDeliveryMedium] = useState('email')
  const { control, handleSubmit, formState: { errors }, setError } = useForm();


  const onFinish = async (values) => {
    props.onSubmit({deliveryMedium: deliveryMedium})
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={24}>
          <Col span={24}>
            <Text>Would you like to send a test via email or use a link to view on mobile ?</Text>
            <Radio.Group
              defaultValue="email"
              style={{marginTop: 10}}
              onChange={(e) => {
                setDeliveryMedium(e.target.value)
                } 
              }
            >
              <Space size={2}>
                <Radio value="email">Email</Radio>
                <Radio value="link">Link to view on mobile</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row> 

        <Row gutter={24}>
          <Col>
            <Button className="ag-gray-button-outline" htmlType="submit" style={{marginTop: 20}}>
              Send to test
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default modifyNotice;