import React from "react";

// others
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { PopupConfirm } from "../../../atoms/Popup";
import {
  AddDocs,
  LeaseTerms,
  AddPayment,
  PaymentPlan,
  GenerateDoc,
  TenantDetails,
  ChangeHistory,
  RenewalTermsModal,
  EditPaymentSchedule,
} from "./modalContent";
import AttachDocs from "./modalContent/AttachDocs";
import RequestBookingExtension from './modalContent/RequestBookingExtension';

interface ModalProps {
  modalPayload: any;
  renewal?: boolean;
  className?: string;
  updateState?: (obj: object) => void;
  setRenewalFormConfirm?:  (obj:Boolean) => void;
  renewalFormConform?:Boolean,
  requestExtension?: any;
  bookingExpiryDate?:any
  unitPaymentPlan?:Object,
  showPaymentPlan?:Boolean,
  setShowPaymentPlan?:(obj) => void;
}
const index = (props: ModalProps) => {         
  // redux states
  const { } = useSelector((state: any) => state.newBooking);
  
  const onModalClose = ()=>{
  if(props.showPaymentPlan){
    props?.setShowPaymentPlan(false)
  }else{
    props.updateState && props.updateState({ modalPayload: { open: false } });
    props?.setRenewalFormConfirm && props?.setRenewalFormConfirm(false);
    props?.requestExtension && props?.requestExtension(false);
  }
   
  }
  return (
    <Modal
      centered
      width={props?.modalPayload?.width || "97%"}
      footer={null}
      className={props.className + " " + props?.modalPayload?.className}
      open={props.showPaymentPlan ? props.showPaymentPlan : props.modalPayload.open}
      title={props.modalPayload.title}
      destroyOnClose={true}
      onCancel={() => onModalClose()}
    >
    { props.modalPayload?.extraText &&  <p className="info-label">{props.modalPayload?.extraText}</p>}

      {props.modalPayload?.iKey === "1" && (
        <TenantDetails parentState={props.updateState} kycDetails={props?.modalPayload?.kycDetails} />
      )}

      {props.modalPayload?.iKey === "2" && (
        <LeaseTerms renewal={props.renewal} parentState={props.updateState} />
      )}

      {props.modalPayload?.iKey === "3" && (
        <EditPaymentSchedule
          renewal={props.renewal}
          parentState={props.updateState}
        />
      )}

      {props.modalPayload?.iKey === "4" && (
        <RenewalTermsModal   onModalClose = {onModalClose} parentState={props.updateState}  renewalFormConform={props?.renewalFormConform} />
      )}

      {props.modalPayload?.iKey === "5" && (
        <AddPayment parentState={props.updateState} renewal={props.renewal} />
      )}

      {props.modalPayload?.iKey === "6" && (
        <AddDocs parentState={props.updateState} />
      )}

      {props.modalPayload?.iKey === "7" && (
        <GenerateDoc parentState={props.updateState} />
      )}

      {props.modalPayload?.iKey === "8" && (
        <PaymentPlan unitPaymentPlan={props?.unitPaymentPlan} parentState={props.updateState} />
      )}

      {props.modalPayload?.iKey === "11" && (
        <ChangeHistory onModalClose = {onModalClose} parentState={props.updateState} />
      )}

      {props.modalPayload?.iKey === "15" && (
        <AttachDocs parentState={props.updateState} data={props?.modalPayload?.attachDoc} />
      )}

      {props.modalPayload?.iKey === "16" && (
        <RequestBookingExtension  requestExtension={props?.requestExtension} bookingExpiryDate={props?.bookingExpiryDate} />
      )}

    </Modal>
  );
};

export default index;
