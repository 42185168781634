import dayjs from "dayjs";

export const dateByformat = (dateParam, formatParam?) => {
  let finalDate = "";
  const date = dayjs(dateParam);
  const format = formatParam ?? "YYYY-MM-DD";

  if (date.isValid())
    finalDate = date.format(format);

  return finalDate;
};

export const formatDate = (date, format = "DD-MM-YYYY") => {
  return date ? dayjs(date).format(format) : '';
};

export const parseDate = (date, format = "DD-MM-YYYY") => {
  return dayjs(date, format);
};

export const determineTerminationType = (moveoutDate, leaseEndDate) => {
  const moveout = dayjs(moveoutDate);
  const leaseEnd = dayjs(leaseEndDate);
  if (moveout.isBefore(leaseEnd)) return 'Early';
  if (moveout.isAfter(leaseEnd)) return 'Late';
  return 'Natural';
};

export const findDeclinedReason = (leaseStatusHistory) => {
  const declinedEntry = [...leaseStatusHistory].reverse().find(
    (item) => item.status === 'renewal_declined'
  );
  return declinedEntry?.reason || '';
};