import React, { memo, useState, useEffect, Fragment } from "react";

// others
import dayjs from "dayjs";
import { Loading } from "./index";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../../molecules/Button";
import { Empty } from "../../../../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { getBookingDetail } from "../../ducks/actions";
import { updatePdcService } from "../../ducks/services";
import { updateBooking } from "../../ducks/actions";
import { RefreshRenwalData } from "../../ducks/actions";
import { Col, Row, Form, Space, message, Typography } from "antd";
import {useParams} from 'react-router-dom'
import {
  DateField,
  InputField,
  ReactSelectField,
  InputNumberField,
} from "../../../../atoms/FormElement";
import {
  payment_modes,
  numberWithCommas,
  disableSingleField,
  filterPaymentPayload,
  filterDescriptionLabel,
} from "../utils";

const { Text } = Typography;

interface IProps {
  renewal?: boolean;
  parentState: (obj: object) => void;
  contractNo?:any
}

let initialState = {
  visible: false,
  paymentSchedule: [],
  totalSum: 0
};

var _ = require("lodash");

const index = (props: IProps) => {
  // hooks initialization
  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);
   const typeOfRenewal = useParams();
   
  let newRenewalType = typeOfRenewal['*'];
  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // states
  let [{ visible, paymentSchedule, totalSum }, setState] = useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    updateState({
      paymentSchedule: filterDescriptionLabel(
        [...bookingDetails?.paymentSchedule],
        false,
        bookingDetails,
        props.renewal,
        newRenewalType
      ),
    });
  }, []);

  // Watch for all form fields changes
  const allValues = useWatch({
    control,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { name }) =>
        paymentSchedule?.length > 0 && handleFields(name, value[name])
    );
    return () => subscription.unsubscribe();
  }, [watch, paymentSchedule]);


  useEffect(() => {
  
    updateState({ totalSum: getSumTotal() });
  }, [allValues, paymentSchedule]);



  const handleFields = (name: string, value: any) => {
    const splitName = name.split("-");
    const index = parseFloat(splitName[1]);
    const copy: Array<any> = [...paymentSchedule];

    switch (splitName[0]) {
      case "vat":
        const actualAmount = parseFloat(
          getValues(`actualAmount-${index}`)?.toString()?.replace(",", "")
        );
        const totalVat: number = (actualAmount * parseInt(value)) / 100;

        if (actualAmount)
          setValue(`totalAmount-${index}`, actualAmount + totalVat);
          // setValue(`totalAmount-${index}`, actualAmount + 0);
        break;
      case "actualAmount":
        const vat = parseFloat(getValues(`vat-${index || 0}`));

        if (vat) {
          const totalVat: number = (parseInt(value) * vat) / 100;
          setValue(`totalAmount-${index}`, parseFloat(value) + totalVat);
        } else setValue(`totalAmount-${index}`, parseFloat(value));
        break;
      case "modeOfPayment":
        if (value?.value === "cash") {
          register(`documentNo-${splitName[1]}`, {
            value: "",
            disabled: true,
          });
          register(`bankName-${splitName[1]}`, {
            value: "",
            disabled: true,
          });
          register(`chequeDate-${splitName[1]}`, {
            value: "",
            disabled: true,
          });
          resetField(`chequeDate-${splitName[1]}`);
          resetField(`bankName-${splitName[1]}`, { defaultValue: "" });
          resetField(`documentNo-${splitName[1]}`, { defaultValue: "" });
        } else {
          setValue(
            `chequeDate-${splitName[1]}`,
            dayjs(copy[splitName[1]]?.chequeDate || copy[splitName[1]]?.dueDate)
          );
        }

        copy[splitName[1]].modeOfPayment = value.value;
        updateState({ paymentSchedule: copy });
        break;
    }
  };

  const checkRentInstallmentTotalAmount = (items) => {
    const rentInstallments = items.filter(transaction => transaction.description === "rent_installment");
    const sum = rentInstallments.reduce((acc, curr) => acc + curr.actualAmount, 0);
    return sum && sum?.toFixed(2);
  }

  // update booking and contract ejari number 

 
  const onSubmit = async (data: any) => {

   

    let dataCopy = {
      updateDataOnly: true,
      leaseId: bookingDetails?.leaseId,
      paymentSchedule: filterPaymentPayload(data, paymentSchedule),
    };


    if (dataCopy?.paymentSchedule?.length > 0) {
      const totalAmount = bookingDetails?.bookingTerms?.renewalAmount || bookingDetails?.bookingTerms?.annualBaseRent;
      let calcSum = checkRentInstallmentTotalAmount(dataCopy?.paymentSchedule);
      if (parseFloat(calcSum) != parseFloat(totalAmount)) {
        message.error(`Sum of rent installments must be equal to contract rent. Please correct and try again`);
        updateState({ visible: false });
        return;
      }
    }

    try {
      const response = await updatePdcService(dataCopy);
      
     
      updateState({ visible: false });
      message.success(response.data.message);
      props.parentState({ modalPayload: { open: false } });
         dispatch(RefreshRenwalData());
    } catch (error) {
      updateState({ visible: false });
      message.error(error.response?.data?.message);
    }
  };

  const textStyle = { fontSize: "12px" };
  const isPayment = paymentSchedule.length > 0;

  const getSumTotal = () => {

    let sum = 0;
    isPayment && paymentSchedule?.forEach((item, index) => {
      if (item?.description == "rent_installment" && getValues(`actualAmount-${index}`)) {
        let valString = getValues(`actualAmount-${index}`)?.toString();
        let val = valString?.replace(",", "")
        sum = sum + parseFloat(val);
      }
    });
    return sum && sum?.toFixed(2);
  }

 const dispatch:any = useDispatch();
  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      onFinish={handleSubmit(onSubmit)}
      className="bf-main view-booking edit-payment-schedule"
    >
      <Loading visible={visible} />

      {isPayment && (
        <Row
          gutter={[24, 24]}
          style={{ letterSpacing: "1.2px", marginBottom: "8px" }}
        >
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-1">
              DESCRIPTION
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              AMOUNT
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              VAT %
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              TOTAL AMOUNT
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              PAYMENT MODE
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              DOCUMENT NO
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              BANK NAME
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              DUE DATE
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              CHEQUE DATE
            </Text>
          </Col>
        </Row>
      )}

      {paymentSchedule.map((item: any, index: number) => {
        const modeOfPayment = item?.modeOfPayment
          ? payment_modes.filter(
            (ele: any) => ele.value.toLowerCase() ===  item?.modeOfPayment.toLowerCase()
          )
          : payment_modes[1];
        return (
          <Fragment>
            <Row gutter={24} style={{ position: "relative" }}>
              <Col span={3}>
                <InputField
                  disabled={true}
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`description-${index}`}
                  initValue={item?.descriptionLabel}
                  validate={errors[`description-${index}`] && "error"}
                />
              </Col>

              <Col span={2}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  iProps={{ min: 1 }}
                  rules={{ required: "required" }}
                  fieldname={`actualAmount-${index}`}
                  validate={errors[`actualAmount-${index}`] && "error"}
                  initValue={numberWithCommas(item?.actualAmount || "")}
                  disabled={disableSingleField(
                    item?.chequeStatus,
                    item?.description,
                    props.renewal
                  )}
                />
              </Col>

              <Col span={2}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  iProps={{ min: 0 }}
                  fieldname={`vat-${index}`}
                  rules={{ required: "required" }}
                  validate={errors[`vat-${index}`] && "error"}
                  initValue={numberWithCommas(item?.vat || 0)}
                  disabled={disableSingleField(
                    item?.chequeStatus,
                    item?.description,
                    props.renewal
                  )}
                />
              </Col>

              <Col span={3}>
                <InputNumberField
                  disabled={true}
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`totalAmount-${index}`}
                  validate={errors[`totalAmount-${index}`] && "error"}
                  initValue={numberWithCommas(item?.totalAmount || "")}
                />
              </Col>

              <Col span={3}>
                <ReactSelectField
                  control={control}
                  isRequired={true}
                  // defaultValue={modeOfPayment}
                  initValue={modeOfPayment}
                  selectOption={payment_modes}
                  rules={{ required: "required" }}
                  fieldname={`modeOfPayment-${index}`}
                  validate={errors[`modeOfPayment-${index}`] && "error"}
                  iProps={{
                    isDisabled: disableSingleField(
                      item?.chequeStatus,
                      item?.description,
                      props.renewal
                    ) || (bookingDetails?.directDebit == true && item?.description == "rent_installment"),
                  }}
                />
              </Col>

              <Col span={3}>
                <InputField
                  control={control}
                  initValue={item?.documentNo}
                  fieldname={`documentNo-${index}`}
                  validate={errors[`documentNo-${index}`] && "error"}
                  disabled={
                    item?.modeOfPayment === "cash" ||
                    disableSingleField(
                      item?.chequeStatus,
                      item?.description,
                      props.renewal
                    )
                  }
                />
              </Col>

              <Col span={2}>
                <InputField
                  control={control}
                  initValue={item?.bankName}
                  fieldname={`bankName-${index}`}
                  validate={errors[`bankName-${index}`] && "error"}
                  disabled={
                    item?.modeOfPayment === "cash" ||
                    disableSingleField(
                      item?.chequeStatus,
                      item?.description,
                      props.renewal
                    )
                  }
                />
              </Col>

              <Col span={3}>
                <DateField
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`dueDate-${index}`}
                  validate={errors[`dueDate-${index}`] && "error"}
                  initValue={item?.dueDate ? dayjs(item?.dueDate, "YYYY-MM-DD") : dayjs()}
                  disabled={disableSingleField(
                    item?.chequeStatus,
                    item?.description,
                    props.renewal
                  )}
                />
              </Col>

              <Col span={3}>
                <DateField
                  control={control}
                  fieldname={`chequeDate-${index}`}
                  validate={errors[`chequeDate-${index}`] && "error"}
                  initValue={(item?.chequeDate || bookingDetails?.leaseTerms?.dueDate || item?.dueDate) ? dayjs(
                    item?.chequeDate || bookingDetails?.leaseTerms?.dueDate || item?.dueDate, //added fixes 8266
                    "YYYY-MM-DD"
                  ) : dayjs()}
                  disabled={
                    item?.modeOfPayment === "cash" ||
                    disableSingleField(
                      item?.chequeStatus,
                      item?.description,
                      props.renewal
                    )
                  }
               
                />
              </Col>
            </Row>
          </Fragment>
        );
      })}
      <Row>
        {/* <Col span={5}></Col> */}
        <Col span={3}>{totalSum && (<p className="totalsum-text">Total Rent  </p>)}</Col>
        <Col span={3}>{totalSum && (<p className="totalsum-amount">{numberWithCommas(totalSum)}</p>)}</Col>
      </Row>

      {!isPayment && (
        <Row style={{ justifyContent: "center" }}>
          <Empty
            width="200px"
            height="200px"
            style={{ alignContent: "center" }}
          />
        </Row>
      )}

      {isPayment && (
        <Row gutter={24} className="footer">
          {bookingDetails?.bookingTerms?.annualBaseRent && (
           <>
            <Col span={12} className="display-amount">
              <p className="amount">{`AED ${numberWithCommas(
                bookingDetails?.bookingTerms?.renewalAmount ||
                bookingDetails?.bookingTerms?.annualBaseRent
              )} / `}</p>
              <p className="year">Year</p>
            </Col>
            
           </>
            
          )}
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            />

            <Button
              type="submit"
              label="Update"
              className="cc-fotter_next-button"
            />
          </Space>
        </Row>
      )}
    </Form>
  );
};

export default memo(index);


